import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./PaymentTypeSelect.module.scss";
import { CreditCardStatus, ICardItem } from "models/payment/ICard";
import { AddCircle, ChevronRight, CloseOutlined } from "@material-ui/icons";
import { Button, IconButton, Modal } from "@material-ui/core";
import CardApi from "services/Api/card/CardApi";
import { CardItem } from "components/CardItem/CardItem";
import { GetCardTypeImage } from "components/getTypeCard/GetTypeCard";
import { NewCard } from "pages/newCard/NewCard";
import { ReactComponent as Pix } from "assets/icons/pix_contained.svg";
import { useUi } from "context/ui/UIContext";

export interface IPaymentTypeSelect {
  type?: "Pix" | "Credit" | null;
  card?: ICardItem | null;
  handlePaymentType: (type: "Pix" | "Credit", _card: ICardItem | null) => void;
}

const PaymentTypeSelect: FC<IPaymentTypeSelect> = ({
  type,
  card,
  handlePaymentType,
}) => {
  const [selectCardOpen, setselectCardOpen] = useState(false);
  const [openAddCard, setopenAddCard] = useState(false);
  const [cards, setcard] = useState<ICardItem[]>([]);
  const { isMobile } = useUi();

  const firstLoad = useRef(true);

  const getCards = useCallback(() => {
    CardApi.getCards().then((response) => {
      if (response.status === 200) {
        setcard(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      getCards();
    }
  }, [getCards]);

  const onCloseAddNewCard = useCallback(() => {
    setopenAddCard(false);
    getCards();
  }, [getCards]);

  const selectPaymentType = useCallback(
    (type: "Pix" | "Credit", _card: ICardItem | null) => {
      handlePaymentType(type, _card);
      setselectCardOpen(false);
    },
    [handlePaymentType]
  );

  return (
    <div
      id={styles.PaymentTypeSelect}
      style={{ width: isMobile ? "100%" : undefined }}
    >
      <Button
        variant="outlined"
        className={styles.input}
        onClick={() => setselectCardOpen(true)}
      >
        {type === "Credit" ? (
          <div>
            Cartão de crédito:{" "}
            <b>{`${card?.brandDescription} **** ${card?.finalNumber}`}</b>
          </div>
        ) : type === "Pix" ? (
          <div>Pix</div>
        ) : (
          <div>Selecione uma forma de pagamento</div>
        )}
        <ChevronRight />
      </Button>
      <Modal
        open={selectCardOpen}
        onClose={() => {
          setselectCardOpen(false);
        }}
        className={styles.modalContainer}
      >
        <div className={styles.modalContent}>
          <div>
            <h4>Forma de pagamento</h4>
            <IconButton onClick={() => setselectCardOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </div>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setopenAddCard(true)}
          >
            Adicionar cartão
            <AddCircle />
          </Button>
          {cards?.length > 0 &&
            cards.map((card) => (
              <CardItem
                textTop={`xxxx.xxxx.xxxx.${card.finalNumber}`}
                textBottom={card.brandDescription}
                imageSrc={GetCardTypeImage(card.brandDescription)}
                onClick={() => selectPaymentType("Credit", card)}
                autenticated={
                  card.status === CreditCardStatus.VALIDATED ? true : false
                }
                key={card.id}
                iconName="credit_card"
              />
            ))}
          <Button
            variant="outlined"
            fullWidth
            onClick={() => selectPaymentType("Pix", null)}
          >
            <Pix />
            PIX
          </Button>
        </div>
      </Modal>
      <Modal
        open={openAddCard}
        onClose={() => setopenAddCard(false)}
        className={styles.modalCardContainer}
      >
        <NewCard
          onCancel={() => onCloseAddNewCard()}
          onConfirm={() => onCloseAddNewCard()}
          title="Adicionar cartão"
        />
      </Modal>
    </div>
  );
};

export default PaymentTypeSelect;
